import React, { Component } from 'react'
import {graphql} from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/contactForm"

import envelope from '../images/envelope.svg'
import phone from '../images/phone.svg'

export default class contacts extends Component {
  constructor(props) {
    super(props)

    this.state = {
         
    }
  }

  render() {
    const contactImage = this.props.data.contactImage.childImageSharp.fluid
    return (
      <Layout>
      <SEO title="Contacts" />
      <section className="container">
        <h1 className="relative orange-underline text-center mb-12">Contacts</h1>
        <div className="flex flex-col-reverse md:flex-row shadow rounded bg-grayDarkest">
          <div className="mx-5 py-5 md:mx-12 md:pt-12 w-9/12">
            <Form />
          </div>
          <div className="bg-orange">
          <BackgroundImage fluid={contactImage} className="padded" imgStyle={{ objectFit: 'contain' }}>
            <p className="mb-3">Whether you're a new, existing or returning customers we 
            want to make it easy for you to get in touch with us.</p>
            <p className="mb-3">Please, fill the form or email you with details of your query, 
            your name and your phone number.</p>
            <p>We look forward to hearing from you.</p>
            <a className="text-gray-800 no-underline hover:underline" href="mailto:info@landl-build.com"><p className="mb-3 flex"><img className="pr-3" src={envelope} alt="Email" />info@landl-build.com</p></a>
            <a className="text-gray-800 no-underline hover:underline" href="tel:07926651960"><p className="flex"><img className="pr-3" src={phone} alt="Phone" />079 266 519 60</p></a>
          </BackgroundImage>
          </div>
        </div>
      </section>
    </Layout>
    )
  }
}

export const query = graphql`
  query {
    contactImage: file(relativePath: { eq: "contact.png"}) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`