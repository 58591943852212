import React, {Component} from "react"

export default class Form extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      disabled: false,
      success: false, 
      showError: false, 
      errorMsg: '', 
    }
  }

  handleSubmit = e => {
    this.setState({disabled: true})
    const data = {
      "Name": this.state.name,
      "Contact": this.state.contact,
      "Message": this.state.message
    }

    if (!this.state.address && !this.state.disabled) {
      fetch('/.netlify/functions/sendEmail', {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then(results => {
        console.log(results)
        if (results['status'] === 200) {
          this.setState({success: true, showError: false})
        } else {
          this.setState({errorMsg: results.errorCode, showError: true, success: false})
        }
      })
      .catch(error => {
        this.setState({errorMsg: error.errorCode, showError: true, success:false})
        console.log(error)
      })
    }

  e.preventDefault();
  }

  handleChange = e =>  this.setState({ [e.target.name]: e.target.value });

  render() {
    const showMessage = this.state.success ? "block" : "hidden"
    const showError = this.state.showError ? "block" : "hidden"

    return (
      <form name="contact us" className="text-sm" onSubmit={this.handleSubmit} method="post" >
      <div>
        <label className="text-white">
          Name
          <input 
          className="appearance-none border-2 rounded w-full py-2 px-3 mb-6 mt-2 leading-tight focus:outline-none focus:border-orange shadow text-grayDarkest" 
          type="text" 
          name="name"
          onChange={this.handleChange} 
          required 
          placeholder="John Smith"
        />
        </label>
      </div>
      <div className="hidden">
        <label className="text-white">
          Address
          <input 
          className="appearance-none border-2 rounded w-full py-2 px-3 mb-6 mt-2 leading-tight focus:outline-none focus:border-orange shadow text-grayDarkest" 
          type="text" 
          name="address" 
          onChange={this.handleChange} 
          required 
          placeholder="23rd Rd."
          aria-hidden="true"
        />
        </label>
      </div>
      <div>
        <label className="text-white">
          Contact information
          <input
          className="appearance-none border-2 rounded w-full py-2 px-3 mb-6 mt-2 leading-tight focus:outline-none focus:border-orange shadow text-grayDarkest"
          type="text" 
          name="contact" 
          onChange={this.handleChange} 
          required 
          placeholder="john.smith@example.com"/>
        </label>
      </div>
      <div>
      <label className="text-white">
        Message
        <textarea 
        className="appearance-none border-2 rounded w-full py-2 px-3 mb-6 mt-2 leading-tight focus:outline-none focus:border-orange shadow text-grayDarkest" 
        name="message" 
        rows="5" 
        onChange={this.handleChange} 
        required placeholder="Your message here..." />
      </label>
      </div>
        <div className="flex justify-center">
          <button 
            className="shadow bg-orange orange-light focus:shadow-outline focus:outline-none py-1 px-3 rounded" 
            type="submit" 
            value="Submit"
            onClick={this.handleSubmit}
            disabled={this.state.disabled}
          >
            Submit
          </button>
        </div>
        <div className={`mt-5 ${showMessage}`}>
          <span className="flex justify-center bg-green-200 border-2 border-green-600 text-green-600 text-sm rounded px-3 py-2"> Message sent successful! </span>
        </div>
        <div className={`mt-5 ${showError}`}>
          <span className="flex justify-center bg-red-200 border-2 border-red-600 text-red-600 text-sm rounded px-3 py-2"> Oops! An error occurred when submitting - {this.state.errorMsg} </span>
        </div>
    </form>
    )
  }
}